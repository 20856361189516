<template>
  <v-list-item
    v-if="item"
    :to="{ name: 'Product', params: { slug: item.product.slug } }"
    class="list-item px-3 py-5"
    flat
  >
    <v-row no-gutters class="flex-lg-nowrap align-center">
      <v-row no-gutters class="col-md-6 col-lg-4 flex-md-nowrap align-start">
        <v-col cols="3" md="auto" lg="4">
          <v-list-item-avatar
            width="80"
            height="80"
            rounded="0"
            class="ma-0 ml-n2"
          >
            <img
              :src="item.product.mediaURL"
              :alt="item.product.name"
              onerror="this.onerror=null;this.src='/no-icon.png'"
            />
          </v-list-item-avatar>
        </v-col>
        <v-col cols="9" md="8" lg="8">
          <v-list-item-content class="default--text pa-0 pa-sm-2">
            <div class="font-weight-bold text-body-2">
              {{ item.product.name }}
            </div>
            <div class="text-uppercase text-body-2">
              {{ item.product.shortDescr }}
            </div>
          </v-list-item-content>
        </v-col>
      </v-row>
      <v-col
        cols="12"
        md="6"
        class="d-flex flex-nowrap flex-justify-space-between align-center mb-sm-0 wrap-xs-column"
      >
        <ProductPrice
          :class="something"
          md="6"
          :product="item.product"
          :isList="true"
        />
        <template v-if="isActive">
          <div
            class="product-manipulation col-md-6 pa-0 d-flex flex-nowrap justify-end justify-lg-center align-center"
          >
            <!--border inline style to be removed later-->
            <QtyWrap
              :key="key"
              :selectedOptions="selectedOptions"
              :product="product"
              :position="position"
            />
          </div>
        </template>
        <div v-else class="not-available">
          {{ $t("product.notAvailable") }}
        </div>
      </v-col>
      <v-col class="mt-5 mt-lg-0 d-flex justify-space-between">
        <v-btn
          outlined
          class="col-5 rounded-lg"
          color="primary"
          elevation="0"
          @click.stop.prevent="addToCart(quantity)"
        >
          <v-icon>$cart</v-icon>
        </v-btn>
        <v-btn
          class="col-5 rounded-lg"
          outlined
          color="primary"
          v-on:click.prevent="removeItem()"
        >
          <v-icon>$delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-list-item>
</template>
<style lang="scss" scoped>
.list-item {
  border-bottom: 1px solid var(--v-grey-lighten1);
  .price_block {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      justify-content: left !important;
    }
  }
  .product-price.is-list {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .product-manipulation,
  .qty-wrap {
    gap: 15px;
  }
  // .product-price {
  //   .cur-price {
  //     font-size: 16px;
  //     color: var(--v-primary-base);
  //   }
  //   .price-um {
  //     text-decoration: line-through;
  //   }
  // }

  .gross-total {
    font-size: 18px;
    font-weight: 700;
  }
  &:last-of-type {
    border-bottom: none;
  }
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    .promo_discount {
      flex-direction: column !important;
    }
  }
  .promo_discount .old-price {
    margin-bottom: 8px !important;
    min-width: 105px;
  }
  @media (max-width: 380px) {
    .wrap-xs-column {
      flex-wrap: wrap;
    }
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
import QtyWrap from "@/components/product/QtyWrap.vue";
import CatalogService from "~/service/catalogService";
import CartService from "~/service/cartService";
import { mapActions } from "vuex";

export default {
  name: "ListItem",
  components: { ProductPrice, QtyWrap },
  props: ["item", "isActive"],
  // mixins: [productMixin],

  computed: {
    quantity() {
      // let item = this.item;
      if (this.item === null || this.item.product === null) return 0;
      if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
        //Handling floating point decimals issues
        var val = (this.item.weight * 100).toFixed(0);
        val = val * CatalogService.getMultiplier(this.item.product);
        val = val / 100;
        return val;
      } else {
        return this.item.quantity;
      }
    },
    quantityPerUnit() {
      let quantity = this.quantity;
      if (quantity === 0) return;
      if (
        this.item.product.productInfos.TIPOLOGIA === "Sfuso" &&
        this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
      ) {
        try {
          var singleUnit = Math.floor(
            quantity /
              parseFloat(
                this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
              )
          );
          var singleUnitUm = this.item.product.productInfos
            .WEIGHT_SELLING_SINGLE_UNIT_UM
            ? this.item.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM
            : "pz";
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      if (this.item.product.productInfos.TIPOLOGIA === "Confezione") {
        try {
          let singleUnit =
            quantity *
            parseFloat(this.item.product.productInfos.WEIGHT_SELLING);
          singleUnit = (singleUnit * 1000).toFixed(0);
          singleUnit = singleUnit / 1000;
          let singleUnitUm = this.item.product.productInfos.WEIGHT_UNIT_SELLING;
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      return null;
    },
    unit() {
      if (this.item.product.productInfos) {
        if (this.item.product.productInfos.TIPOLOGIA == "Sfuso") {
          return this.item.product.productInfos.WEIGHT_UNIT_SELLING;
        } else {
          return this.item.product.productInfos.UNIT_SELLING != null
            ? this.item.product.productInfos.UNIT_SELLING
            : "pz";
        }
      } else {
        return null;
      }
    }
  },
  methods: {
    ...mapActions({
      addProduct: "cart/addProduct"
    }),
    removeItem() {
      this.$emit("removeItem", {
        itemId: this.item.itemId,
        product: this.item.product
      });
    },
    plus() {
      const newQuantity = CartService.plus(this.item.product, this.quantity);

      this.$emit("update", { newQuantity, item: this.item });
    },
    async minus() {
      const newQuantity = CartService.minus(this.item.product, this.quantity);

      let res = true;
      if (newQuantity <= 0) {
        res = await this.$dialog.confirm({
          text: global.vm.$t("message.removeItemFromList")
        });
      }
      if (res) this.$emit("update", { newQuantity, item: this.item });
    },
    addToCart() {
      this.addProduct({ product: this.item.product, quantity: this.quantity });
    },
    hasPromo(item) {
      return (
        item.product.priceStandardDisplay &&
        item.unitPrice !== item.product.priceStandardDisplay
      );
    }
  }
};
</script>
